
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, EstimationClient, FabricEstimationClient, GarmentClient } from '@/services/Services';
import { Watch } from 'vue-property-decorator';
import InfoModal from '@/components/modals/infoModal.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class Start extends Vue {

    selectedGarment: string = "";
    model: OM.CreateEstimationVM = new OM.CreateEstimationVM();
    countries: OM.TextIdentifier[] = [];

    yesOrNoSelect: OM.TextIdentifier[] = [];
    userKnowDetails = true.toString();
    hasFarmingInfo = false.toString();
    hasWoolInfo = false.toString();

    @Watch('$route.params.garmentIdentifier')
    updateGarment() {
        this.init();
    }
    
    created() {
        this.init();
    }

    init() {
        this.yesOrNoSelect = [
            {
                identifier: true.toString(),
                text: this.$localizationService.getLocalizedValue("Yes", "Yes")
            },
            {
                identifier: false.toString(),
                text: this.$localizationService.getLocalizedValue("No", "No")
            }
        ]

        Promise.all([
            GarmentClient.getGarmentTextIdentifier(this.$route.params.garmentIdentifier as string),
            GarmentClient.getGarmentProductionPhases(this.$route.params.garmentIdentifier as string),
            ElectricityEmissionClient.getCountries(true),
        ])
        .then( xs => {
            this.model.garmentIdentifier = this.$route.params.garmentIdentifier as string;
            this.selectedGarment = xs[0].text;
            this.$store.state.phases = xs[1];
            this.countries = xs[2];
        })
    }

    get disabled(){
        if(this.userKnowDetails == true.toString())
            return !this.model.garmentIdentifier || !this.model.sku;
        else 
            return !this.model.garmentIdentifier || !this.model.sku || !this.model.electricityEmissionCountry;
    }

    useKnowDetailsUpdated(value: string){
        this.userKnowDetails = value;

        if(value == true.toString())
            this.model.electricityEmissionCountry = "";
    }
    hasFarmingUpdated(value: string){
        this.model.hasFarmingInfo = value == true.toString();
    }
    hasWoolUpdated(value: string){
        this.model.hasWoolInfo = value == true.toString();
    }

    createEstimation(){
        FabricEstimationClient.createNewEstimation(this.model)
        .then( x => {
            store.state.updateLicenses = store.state.updateLicenses++;

            if(this.model.hasFarmingInfo)
                this.$router.push("/fabric-calculator/farming/" + x);
            else if(this.model.hasWoolInfo)
                this.$router.push("/fabric-calculator/wool/" + x);
            else
                this.$router.push("/fabric-calculator/rawmaterials/" + x);
        })
        .catch(y => {
            var message = this.selectedGarment + ": " + y;

            if(y.Exception) {
                var startPoint = y.Exception.indexOf("Message:");
                var messageNotFiltered = y.Exception.substring(startPoint + 8);
                var endPoint = messageNotFiltered.indexOf("\r\n");
                message = messageNotFiltered.substring(0, endPoint);
            }

            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("Error", "Error"),
                subtitle: message,
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }
}
