import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row mt-3" }
const _hoisted_2 = { class: "col-sm-6" }
const _hoisted_3 = { class: "col-sm-6" }
const _hoisted_4 = { class: "row mt-3" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "col-sm-6" }
const _hoisted_7 = { class: "row mt-3" }
const _hoisted_8 = { class: "col-sm-6" }
const _hoisted_9 = {
  key: 0,
  class: "col-sm-6"
}
const _hoisted_10 = { class: "d-flex justify-content-end mt-3" }
const _hoisted_11 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_form_field, {
            type: "text",
            disabled: true,
            labelKey: "Product Name",
            labelValue: "Product Name",
            placeholderKey: "Product Name",
            placeholderValue: "Product Name",
            modelValue: _ctx.selectedGarment,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedGarment) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_form_field, {
            type: "text",
            labelKey: "SKU",
            labelValue: "SKU",
            placeholderKey: "SKU",
            placeholderValue: "SKU",
            modelValue: _ctx.model.sku,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.sku) = $event))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_form_field, {
            type: "select",
            labelKey: "Do you know all the steps for Cotton Farming?",
            labelValue: "Do you know all the steps for Cotton Farming?",
            placeholderKey: "Do you know all the steps for Cotton Farming?",
            placeholderValue: "Do you know all the steps for Cotton Farming?",
            list: _ctx.yesOrNoSelect,
            modelValue: _ctx.hasFarmingInfo,
            "onUpdate:modelValue": _ctx.hasFarmingUpdated
          }, null, 8, ["list", "modelValue", "onUpdate:modelValue"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_form_field, {
            type: "select",
            labelKey: "Do you know all the steps for Wool Production?",
            labelValue: "Do you know all the steps for Wool Production?",
            placeholderKey: "Do you know all the steps for Wool Production?",
            placeholderValue: "Do you know all the steps for Wool Production?",
            list: _ctx.yesOrNoSelect,
            modelValue: _ctx.hasWoolInfo,
            "onUpdate:modelValue": _ctx.hasWoolUpdated
          }, null, 8, ["list", "modelValue", "onUpdate:modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_form_field, {
            type: "select",
            labelKey: "Do you know all the countries for all steps?",
            labelValue: "Do you know all the countries for all steps?",
            placeholderKey: "Do you know all the countries for all steps?",
            placeholderValue: "Do you know all the countries for all steps?",
            list: _ctx.yesOrNoSelect,
            modelValue: _ctx.userKnowDetails,
            "onUpdate:modelValue": _ctx.useKnowDetailsUpdated
          }, null, 8, ["list", "modelValue", "onUpdate:modelValue"])
        ]),
        (_ctx.userKnowDetails == false.toString())
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_form_field, {
                type: "select",
                labelKey: "Select the origin of the garment",
                labelValue: "Select the origin of the garment",
                placeholderKey: "Select the origin of the garment",
                placeholderValue: "Select the origin of the garment",
                list: _ctx.countries,
                modelValue: _ctx.model.electricityEmissionCountry,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.electricityEmissionCountry) = $event))
              }, null, 8, ["list", "modelValue"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("button", {
        class: "btn btn-tertiary",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.createEstimation && _ctx.createEstimation(...args))),
        disabled: _ctx.disabled
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Continue",
          text: "Continue"
        })
      ], 8, _hoisted_11)
    ])
  ]))
}